<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Estado de pago de ciclos por estudiante
        </p>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="black--text">
              <v-icon left color="naranja">mdi-filter</v-icon>
              Filtros
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Año"
                    :items="data_years"
                    item-text="year"
                    item-value="year"
                    required
                    outlined
                    hide-details
                    v-model="year"
                    @change="getStudentsCyclesPayment"
                    clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              :headers="data.headers"
              :items="data.data"
              :items-per-page="10"
              :search="data.search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de estudiantes y pagos de ciclos
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="data.search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            csv-title="Lista de Pago de Estudiantes por Estado"
                          >
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <download-excel
                            class="btn btn-default"
                            :data="csvData"
                            :fields="data.jsonFields"
                            worksheet="Listado de estudiantes"
                            :name="getFileName"
                          >
                            <v-icon color="green" left>mdi-file-excel</v-icon>
                            Excel
                          </download-excel>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.code }}</td>
                  <td>{{ props.item.first_name }}</td>
                  <td>{{ props.item.last_name }}</td>
                  <td
                    v-for="(payment, index) in props.item.pays"
                    :key="index"
                    :class="`${getColor(payment)}--text font-weight-medium`"
                  >
                    {{ payment }}
                  </td>
                  <!-- <td>
                    {{ props.item.pays }}
                  </td> -->
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "CyclesPayment",
  components: {
    VueJsonToCsv,
  },
  data: () => ({
    data: {
      load: true,
      headers: [],
      data: [],
      filters: {},
      search: "",
      jsonFields: {},
    },
    year: new Date().getFullYear(),
    data_years: [],
  }),
  mounted() {
    this.getYears();
    this.getStudentsCyclesPayment();
  },
  methods: {
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    // data
    getYears: function() {
      this.$http.get(this.api + "pay/year").then(({ data }) => {
        this.data_years = data;
      });
    },
    getStudentsCyclesPayment: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "pay/cycle/" + this.year)
        .then(({ data }) => {
          this.data.data = data.data;
          let headers = [
            { text: "Código", value: "code" },
            { text: "Nombres", value: "first_name" },
            { text: "Apellidos", value: "last_name" },
          ];
          let jsonFields = {
            Código: "code",
            Nombres: "first_name",
            Apellidos: "last_name",
          };
          data.headers.forEach((cycle) => {
            headers.push({
              text: cycle.cycle,
              value: `pays.${cycle.cycle_key}`,
            });
            jsonFields[cycle.cycle] = cycle.cycle_key;
          });
          this.data.headers = headers;
          this.data.jsonFields = jsonFields;
        })
        .catch((err) => {
          console.error(err);
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .finally(() => {
          this.data.load = false;
        });
    },

    getColor(payment) {
      if (payment.includes(", No Pagado")) {
        return "error";
      } else if (payment.includes("($")) {
        return "success";
      } else {
        return "black";
      }
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      user: "getUser",
      actions: "getCurrentActions",
    }),
    filterData() {
      //quitandole la reactividad
      let localDataJSON = JSON.stringify(this.data.data);
      let data = JSON.parse(localDataJSON);
      //descontruccion
      let { program, cycle } = this.data.filters;
      //filtros
      if (program != undefined) {
        data = data.filter((item) => item.program == program);
      }
      if (cycle != undefined) {
        data = data.filter((item) => item.cycle == cycle);
      }
      return data;
    },
    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          if (header.value.includes(".")) {
            let headerArr = header.value.split(".");
            headers[headerArr[headerArr.length - 1]] = { title: header.text };
          } else {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },
    csvData() {
      let data = this.data.data.map((item) => {
        let { pays } = item;
        let element = {
          ...item,
          ...pays,
        };
        return element;
      });

      return data;
    },
    getFileName() {
      const date = new Date().toISOString().slice(0, 10);
      return `Listado de estudiantes con pagos por ciclo ${date}`;
    },
  },
};
</script>
